"use client";
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";

import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../ui/chart";
import { useEffect, useState } from "react";
import { format } from "date-fns";

export function formatDate(dateStr) {
  // Split the input date string into day, month, and year
  const [day, month, year] = dateStr.split("-").map(Number);

  // Create a new Date object from the parsed day, month, and year
  const date = new Date(year, month - 1, day);

  // Format the date to 'MMM DD' using toLocaleDateString
  // const options = { month: "short", day: "2-digit", year: "2-digit" };
  // return date.toLocaleDateString("en-US", options);
  return format(date, "dd MMM yyyy");
}

export function getLabelsAtIntervals(data, numberOfLabels) {
  const labels = [];
  const length = data.length;

  // Calculate the interval step, ensuring at least 1 step
  const step = Math.max(1, Math.floor((length - 2) / (numberOfLabels - 2)));

  // Add the first label
  labels.push(data[0]?.label);

  // Add labels at regular intervals
  for (let i = step; i < length - 1; i += step) {
    if (labels.length < numberOfLabels - 1) {
      labels.push(data[i]?.label);
    }
  }

  // Add the last label
  labels.push(data[length - 1]?.label);

  return labels;
}

export function LineChartComp({ data }) {
  // console.log(data);
  let [chartData, setChartData] = useState([]);

  useEffect(() => {
    setChartData(data);
    // console.log(">>>///", [0, Math.max(...data?.map((x) => Number(x?.value)))]);
  }, [data]);

  const chartConfig = {
    value: {
      label: "Price: ",
      color: data[0]?.color,
    },
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  const getStatus = (time) => {
    const hour = time.getHours();
    const minute = time.getMinutes();
    if (hour < 9 || (hour === 9 && minute < 30)) return "";
    if (hour < 16 || (hour === 16 && minute === 0)) return "";
    return "";
  };

  const customTickFormatter = (timestamp) => {
    if (timestamp) {
      let dataStr = timestamp?.split("-");
      const time = new Date(`${dataStr[2]}-${dataStr[1]}-${dataStr[0]}`);
      const formattedTime = format(time, "dd MMM yyyy");
      // const status = getStatus(time);
      return formattedTime;
    } else {
      return timestamp;
    }
  };

  return (
    <div className="w-full h-[150px] md:h-[300px] ml-1">
      <ChartContainer className=" h-full w-full " config={chartConfig}>
        <AreaChart
          //   accessibilityLayer
          data={chartData}
          margin={{
            left: 12,
            right: 12,
          }}
        >
          <CartesianGrid vertical={false} horizontal={false} syncWithTicks />
          <defs>
            <linearGradient id="fillMobile" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={data[0]?.color} stopOpacity={0.8} />
              <stop offset="99%" stopColor="transparent" stopOpacity={0.4} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="label"
            repeatCount={false}
            tickMargin={2}
            tickFormatter={(value) => formatDate(value)}
            tickLine={false}
            axisLine={false}
            interval="preserveStartEnd"
            padding={{
              left: 5,
            }}
            ticks={[...getLabelsAtIntervals(chartData, 10), 0]?.sort(
              (a, b) => b - a
            )}
          />
          <YAxis
            axisLine={false}
            ticks={[0, Math.max(...data?.map((x) => Number(x?.value)))]}
            tickLine={false}
            hide={true}
          />
          <ChartTooltip
            cursor={false}
            formatter={(x, c) => x + "%"}
            content={
              <ChartTooltipContent
                myFormatter={customTickFormatter}
                custom={true}
                className="bg-black p-2"
                suffix="%"
              />
            }
          />
          <Area
            dataKey="value"
            type="monotone"
            stroke={data[0]?.color}
            strokeWidth={2}
            dot={false}
            fillOpacity={0.4}
            fill="url(#fillMobile)"
          />
        </AreaChart>
      </ChartContainer>
    </div>
  );
}
